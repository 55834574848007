import React, {useState, useEffect} from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'
import { baseURL } from '../config.js';
import '../Componant Style/Header.css'
import menu from '../assets/menu.png'
import close from '../assets/close.png'


import logo from '../logo.jpeg'

export default function Header() {
    
    const navigate = useNavigate();

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        navigate(`/Product/${selectedValue}`);
    };

    const location = useLocation();

    const [cat, setCat] = useState([])

    const [showMenu, setShowMenu] = useState(false)

    let color,bgcolor, border;

    if(location.pathname === '/Product') {
      bgcolor = "rgba(242,101,34,.4)"
      color='white'
      border= "1px solid #54A0BF"
    }

    const isActive = (pathname) => {
        return pathname === location.pathname ? 'active-link' : 'nonActive';
    };

    useEffect(() => {
      const fetchData = async () => {
        try {
          // First Axios GET request
          const response1 = await axios.get(`${baseURL}/getCategorie`);
          setCat(response1.data);

        } catch (error) {
          // Handle errors for both requests here
          console.error('Error fetching data:', error);
        }
      };
    
      fetchData(); // Call the fetchData function

    },[]);
    

  return (
    <div className='headerComponant'>
      <div className='logoContainer'>
        <Link to="/" id='logo'> <img src={logo} alt=''/> </Link>
      </div>
      {(window.innerWidth <= 950 && showMenu) && <ul className='headerList'>
          <img src={close} alt='' onClick={()=>{setShowMenu(false)}} id='closeMenuHeader'/>
          <li>
          <select className= {`Links ${isActive("/Product/Products")} ${isActive("/Product/Ordinateur")} ${isActive("/Product/Imprements")}` } value={location.pathname.replace('/Product/', '').replace("%20", ' ')}  style={{borderColor:bgcolor, color:color, border:border}}   onChange={handleSelectChange}>
                <option value="Choisir la categorie" onClick={()=>{setShowMenu(false)}}>Choisir la categorie</option>
                <option value="Tous">Tous</option>
                { cat.map((item, index) => ( 
                  <option onClick={()=>{setShowMenu(false)}} key={index}  value={item.categorie_name}>{item.categorie_name}</option> 
                ))}
            </select>  
          </li>
          <li>
            <Link className={`Links ${isActive("/")}`} to="/" onClick={()=>{setShowMenu(false)}}>Accueil</Link>
          </li>
          <li>
            <Link className={`Links ${isActive("/Propos")}`} to="/Propos" onClick={()=>{setShowMenu(false)}}>Qui somme nous</Link>
          </li>
          <li>
            <Link className={`Links ${isActive("/Entreprises")}`} to="/Entreprises" onClick={()=>{setShowMenu(false)}}>Espace Entreprises</Link>
          </li>
          <li>
            <Link className={`Links ${isActive("/Partner")}`} to="/Partner" onClick={()=>{setShowMenu(false)}}>Business Partner</Link>
          </li>
        </ul>}

        {window.innerWidth > 950 &&  <ul className='headerList'>
          <img src={close} alt='' onClick={()=>{setShowMenu(false)}} id='closeMenuHeader'/>
          <li>
          <select className= {`Links ${isActive("/Product/Products")} ${isActive("/Product/Ordinateur")} ${isActive("/Product/Imprements")}` } value={location.pathname.replace('/Product/', '').replace("%20", ' ')}  style={{borderColor:bgcolor, color:color, border:border}}   onChange={handleSelectChange}>
                <option value="Choisir la categorie" onClick={()=>{setShowMenu(false)}}>Choisir la categorie</option>
                <option value="Tous">Tous</option>
                { cat.map((item, index) => ( 
                  <option onClick={()=>{setShowMenu(false)}} key={index}  value={item.categorie_name}>{item.categorie_name}</option> 
                ))}
            </select>  
          </li>
          <li>
            <Link className={`Links ${isActive("/")}`} to="/" onClick={()=>{setShowMenu(false)}}>Accueil</Link>
          </li>
          <li>
            <Link className={`Links ${isActive("/Propos")}`} to="/Propos" onClick={()=>{setShowMenu(false)}}>Qui somme nous</Link>
          </li>
          <li>
            <Link className={`Links ${isActive("/Entreprises")}`} to="/Entreprises" onClick={()=>{setShowMenu(false)}}>Espace Entreprises</Link>
          </li>
          <li>
            <Link className={`Links ${isActive("/Partner")}`} to="/Partner" onClick={()=>{setShowMenu(false)}}>Business Partner</Link>
          </li>
        </ul>}

        
        <img id='menuHeader' onClick={() => {setShowMenu(true)}} src={menu}/>
    </div>
  )
}
